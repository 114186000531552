body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  position: absolute;
  width: 500px;
  height: 500px;
  object-fit: cover;
}

.clip-svg {
  -webkit-clip-path: url(#clip);
          clip-path: url(#clip);
}

.App_app__3Kcxr {
  position: relative;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.App_flex__3COvd {
  position: relative;
  display: -webkit-flex;
  display: flex;
}

.App_flexCenter__2u2LN {
  -webkit-align-items: center;
          align-items: center;
}

.App_controls__3Ku39 {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
}

.App_buttons__2E3sn {
  display: -webkit-flex;
  display: flex;
  width: 500px;
  margin-bottom: 40px;
}

.App_button__26yvb {
  display: inline-block;
  height: 40px;
  outline: 0;
  line-height: 40px;
  font-family: inherit;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  padding: 0 16px;
  border: 0;
  border-radius: 4px;
  color: #000;
  background: #ffe058;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0 4px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.App_button__26yvb:hover {
  background: #ffdc46;
}

.App_button__26yvb:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.App_label__164Mw {
  display: inline-block;
  width: 48px;
  text-align: center;
  font-family: monospace;
  font-size: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App_link__Ee7nY {
  color: blue;
  text-decoration: none;
}

.App_link__Ee7nY:hover {
  text-decoration: underline;
}

.App_image__3a0p9 {
  position: absolute;
  width: 500px;
  height: 500px;
  object-fit: cover;
  -webkit-clip-path: url(#clip);
          clip-path: url(#clip);
}

.App_blob__1qqy_ {
  max-width: 100vw;
}

.App_blob__1qqy_ svg {
  max-width: 100%;
}

@media (max-width: 599px) {
  .App_buttons__2E3sn {
    width: 100vw;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .App_button__26yvb {
    height: 60px;
    line-height: 60px;
    margin: 2px 4px;
  }

  .App_flex__3COvd {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .App_range__13mYs {
    width: 300px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }

  .App_rangeContrast__2fixn,
  .App_rangeComplexity__oVQxm {
    height: 100%;
    width: 40px;
    position: absolute;
    top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .App_rangeContrast__2fixn {
    right: 0;
  }

  .App_rangeComplexity__oVQxm {
    left: 0;
  }
}

