.app {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex {
  position: relative;
  display: flex;
}

.flexCenter {
  align-items: center;
}

.controls {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.buttons {
  display: flex;
  width: 500px;
  margin-bottom: 40px;
}

.button {
  display: inline-block;
  height: 40px;
  outline: 0;
  line-height: 40px;
  font-family: inherit;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  padding: 0 16px;
  border: 0;
  border-radius: 4px;
  color: #000;
  background: #ffe058;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0 4px;
  flex: 1;
}

.button:hover {
  background: #ffdc46;
}

.button:active {
  transform: scale(0.98);
}

.label {
  display: inline-block;
  width: 48px;
  text-align: center;
  font-family: monospace;
  font-size: 16px;
  user-select: none;
}

.link {
  color: blue;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.image {
  position: absolute;
  width: 500px;
  height: 500px;
  object-fit: cover;
  clip-path: url(#clip);
}

.blob {
  max-width: 100vw;
}

.blob svg {
  max-width: 100%;
}

@media (max-width: 599px) {
  .buttons {
    width: 100vw;
    flex-direction: column;
  }

  .button {
    height: 60px;
    line-height: 60px;
    margin: 2px 4px;
  }

  .flex {
    flex-direction: column;
  }

  .range {
    width: 300px;
    transform: rotate(90deg);
  }

  .rangeContrast,
  .rangeComplexity {
    height: 100%;
    width: 40px;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
  }

  .rangeContrast {
    right: 0;
  }

  .rangeComplexity {
    left: 0;
  }
}
