img {
  position: absolute;
  width: 500px;
  height: 500px;
  object-fit: cover;
}

.clip-svg {
  clip-path: url(#clip);
}
